@font-face {
  font-family: "JetBrains Mono Nerd Font";
  src: url("./fonts/JetBrainsMono/fonts/webfonts/JetBrainsMono-Regular.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@keyframes backgroundShift {
  0% {
    background-color: #2c3e50;
  }
  25% {
    background-color: #7e559b;
  }
  50% {
    background-color: #764ba2;
  }
  75% {
    background-color: #667eea;
  }
  100% {
    background-color: #2c3e50;
  }
}

.dynamic-background {
  animation: backgroundShift 60s infinite;
}

html {
  scroll-padding-top: 5rem;
}

@media (min-width: 768px) {
  html {
    scroll-padding-top: 5rem;
  }
}

body {
  font-family: "JetBrains Mono Nerd Font", monospace;
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #667eea, #764ba2);
  min-height: 100vh;
  color: #fff;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

body.dynamic-background {
  background: inherit;
  animation: backgroundShift 60s infinite;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  text-align: justify; /* Justify text in the main content */
}

.justified-text {
  text-align: justify; /* Justify text in paragraphs and other text elements */
}

main {
  flex: 1;
}

nav {
  background: inherit;
  color: #fff;
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  transition:
    background 0.3s ease,
    box-shadow 0.3s ease;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: backgroundShift 60s infinite; /* Ensuring seamless background */
}

nav.scrolled {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.nav-items {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  transition:
    max-height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  color: inherit;
  text-decoration: none;
}

.nav-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-toggle {
    display: block;
  }

  nav {
    padding: 1rem 1rem; /* Adjust padding for spacing */
  }

  .nav-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: inherit; /* Ensuring seamless background */
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Box shadow at the bottom */
  }

  .nav-items.nav-visible {
    max-height: 300px; /* Adjust as needed */
    opacity: 1;
  }

  .nav-items.nav-hidden {
    max-height: 0;
    opacity: 0;
  }

  .nav-items li {
    margin: 1rem 0; /* Add margin between menu items */
  }

  .nav-toggle {
    right: 1rem;
  }
}

@media (min-width: 769px) {
  .nav-title {
    display: none; /* Hide on larger screens */
  }

  .nav-items {
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

header {
  text-align: center;
  padding: 4rem 0;
  margin-top: 3rem;
}

section {
  padding: 2rem 0;
  text-align: center;
}

footer {
  background: rgba(51, 51, 51, 0.9);
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.proficiencies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.proficiency {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.proficiency p {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #fff;
}

@keyframes jiggle {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: scale(1.1) rotate(-5deg);
  }
  50% {
    transform: scale(1.2) rotate(5deg);
  }
  75% {
    transform: scale(1.1) rotate(-5deg);
  }
}

.jiggle-grow {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.jiggle-grow:hover {
  animation: jiggle 0.5s ease-in-out;
  animation-iteration-count: 1;
}

.custom-list {
  list-style: disc;
  padding-left: 0;
  text-align: center;
}

.custom-list li {
  display: inline-block;
  position: relative;
  padding-left: 1.5em;
  text-align: left;
}

.custom-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #fff;
}
